.wrapper {
  max-width: 1200px;

  @include media-query($on-laptop) {
    max-width: 1200px;
  }
}

.page-heading {
  text-align: center;
}

.post-meta {
  .post-meta-table {
    td {
      padding: 0px 5px;
    }
  }
}